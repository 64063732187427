
<template>

  <div id="smooth-wrapper">
      <div id="smooth-content">
        <TheMash />

</div>
  </div>
  <div>

  </div>
</template>

<style>
#smooth-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%; /* or another value that fits your design */
  z-index: 0;
}

.cls-1 {  
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
rect {
  pointer-events: all;
}

</style>
